<template>
  <div class="team_invite_expired">
    <div class="container">
      <div class="main-area">
        <div class="content">
          <h3>トークン切れになりました。ダッシュボードへ遷移します</h3>
          <div class="btn_group">
            <button class="btn-md btn-blue shadow" type="button" @click="goDashboard">ダッシュボードへ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="@/presentation/views/user/team/team-invite-expired/TeamInviteExpired.ts">
</script>

<style lang="scss" src="@/presentation/views/user/team/team-invite-expired/TeamInvitedExpired.scss" scoped>
</style>