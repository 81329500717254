import { reactive, defineComponent } from "vue";
import routePath from "@/commons/RoutePath";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "TeamInviteExpired",
    setup: function () {
        var state = reactive({});
        var router = useRouter();
        function goDashboard() {
            return router.push(routePath.USER_DASHBOARD);
        }
        return {
            state: state,
            goDashboard: goDashboard
        };
    }
});
